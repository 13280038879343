<template>
  <div id="invite">
    <div class="swiper-pc">
      <img class="banner-img" :src="require(`@/assets/invite_${$i18n.locale}.jpg`)" alt=""/>
    </div>
    <div class="swiper-xs">
      <img class="banner-img" :src="require(`@/assets/invite_${$i18n.locale}_xs.png`)" alt=""/>
    </div>
    <div class="invite-code-pc flex">
      <div>{{$t('invite.code')}}</div>
      <div class="code">{{info.userInfo.division_invite}}</div>
      <van-button @click="copy('.code-copy',info.userInfo.division_invite)" class="code-copy" round size="large" color="#3A9EFB">
        {{$t('invite.copy')}}
      </van-button>
    </div>
    <div class="container">
      <div class="invite-container-pc">
        <div class="title">{{$t('invite.text_1')}}</div>
        <div class="rule" v-if="info.userInfo.division_invite" @click="ruleHandle">{{$t('invite.rules')}}</div>
        <div class="step">
          <div class="step-item">
            <div class="flex-center-between">
              <span class="number">1</span>
              <van-image width="50" height="50" fit="contain" :src="require('@/assets/invite_step_1.png')"/>
            </div>
            <div class="title">{{$t('invite.text_2')}}</div>
            <div class="text">{{$t('invite.text_3')}}</div>
          </div>
          <div class="step-item">
            <div class="flex-center-between">
              <span class="number">2</span>
              <van-image width="50" height="50" fit="contain" :src="require('@/assets/invite_step_2.png')"/>
            </div>
            <div class="title">{{$t('invite.text_4')}}</div>
            <div class="text">{{$t('invite.text_5')}}</div>
          </div>
          <div class="step-item">
            <div class="flex-center-between">
              <span class="number">3</span>
              <van-image width="50" height="50" fit="contain" :src="require('@/assets/invite_step_3.png')"/>
            </div>
            <div class="title">{{$t('invite.text_6')}}</div>
            <div class="text">{{$t('invite.text_7')}}</div>
          </div>
        </div>
        <div class="flex" style="margin-bottom: 120px;">
          <van-button v-if="isApp != 'wx'" @click="share('.share-pc')" class="share-pc" round size="large" color="#3A9EFB">
            {{$t('invite.share')}}
          </van-button>
        </div>
      </div>
      <div class="invite-container-xs">
        <div class="invite-code">
          <div class="title">{{$t('invite.mycode')}}</div>
          <div class="code flex">
            <span>{{info.userInfo.division_invite}}</span>
            <span v-if="info.userInfo.division_invite" class="copy" @click="copy('.copy',info.userInfo.division_invite)">{{$t('invite.copy')}}</span>
          </div>
        </div>
        <div class="invite-explain">
          <div class="title">{{$t('invite.text_1')}} <span class="rule" v-if="info.userInfo.division_invite" @click="ruleHandle">{{$t('invite.rules')}}</span></div>
          <div class="steps">
            <div class="step-item">
              <div>
                <van-tag size="medium" round color="#3A9EFB">1</van-tag>
                <span class="title">{{$t('invite.text_2')}}</span>
              </div>
              <div class="text">{{$t('invite.text_3')}}</div>
            </div>
            <div class="step-item">
              <div>
                <van-tag size="medium" round color="#3A9EFB">2</van-tag>
                <span class="title">{{$t('invite.text_4')}}</span>
              </div>
              <div class="text">{{$t('invite.text_5')}}</div>
            </div>
            <div class="step-item">
              <div>
                <van-tag size="medium" round color="#3A9EFB">3</van-tag>
                <span class="title">{{$t('invite.text_6')}}</span>
              </div>
              <div class="text">{{$t('invite.text_7')}}</div>
            </div>
          </div>
          <van-button v-if="isApp != 'wx'" round @click="share('.share-xs')" class="share-xs" size="large" color="#3A9EFB">
            {{$t('invite.share')}}
          </van-button>
        </div>
      </div>
      <van-divider class="divider">{{$t('invite.records')}}</van-divider>
      <div class="records flex" v-if="info.spread.count">
        <div class="item flex-center-column">
          <div class="flex-center-between">
            <span class="number">{{info.spread.count}}</span>
          </div>
          <div class="text">{{$t('invite.invited')}}</div>
        </div>
        <div class="item flex-center-column">
          <div class="flex-center-between">
            <span class="number">{{info.spread.count}}</span>
            <van-button v-if="isApp != 'wx'" class="use" @click="useHandle" round size="small" color="#3A9EFB">{{$t('invite.use')}}</van-button>
          </div>
          <div class="text">{{$t('invite.coupons')}}</div>
        </div>
      </div>
      <div v-else class="empty">{{$t('invite.no_record')}}</div>
    </div>
    <van-overlay :show="shareShow" :lock-scroll="false" z-index="1000">
      <div class="share-wrapper" @click.stop>
        <div class="share-inner">
          <div class="flex-center-end">
            <van-icon color="#666666" class="close" @click="shareShow = false" style="cursor: pointer;" size="20px" name="cross" />
          </div>
          <div class="share-cont">
            <van-image fit="contain" width="42" :src="require('@/assets/share.png')"/>
            <div class="title">{{$t('invite.text_8')}}</div>
            <div class="text">{{$t('invite.text_9')}}</div>
            <van-button @click="shareShow = false" style="width: 88px;" round size="small" color="#3A9EFB">
              {{$t('invite.know')}}
            </van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { mapState,mapGetters,mapActions } from 'vuex';
import Clipboard from 'clipboard';
import { message } from 'ant-design-vue'
import WxSDK from 'jweixin-module';
export default{
  name: "Invite",
  data() {
    return {
      shareShow:false,
      info:{
        spread:{
          count:0
        },
        spreadDesc:'',
        userInfo:{
          division_invite:''
        }
      }      
    }
  },
  computed:{
    ...mapState(['isApp']),
    ...mapState("user", ["token"]),
    ...mapGetters('user', ['isLogin']),
  },
  mounted(){
    window.webRefresh = (token) => {
      console.log('更新token')
      this.AuthTokenLogin(decodeURIComponent(token))
      this.init()
		}
    this.init()
  },
  methods:{
    ...mapActions('user',['AuthTokenLogin']),
    goLogin(){
      if(this.isApp == "wx"){
        WxSDK.miniProgram.redirectTo({
          url: `/pages/login/login?backtype=Applets&backurl=${window.location.href}`
        })
      }else if(this.isApp == "ios"){
        window.webkit.messageHandlers.startLoginPage.postMessage({});
      }else if(this.isApp == "android"){
        window.hotelapp.startLoginPage()
      }else{
        console.log('其它')
      }
    },
    async init(){
      if(!this.isLogin) return this.goLogin()
      const result = await this.$http.get(this.APIURL.PUBLIC.INVITE)
      if(result.code == 401){
        setTimeout(()=>{
          this.goLogin()
        },2000)
        return
      }
      if(result.code ==1)this.info = result.data
    },
    ruleHandle(){
      if(!this.isLogin) return this.goLogin()
      let url = { name: 'ActivityRules',query:{type:16,token:this.token}}
      if(this.isApp == "ios"&&this.$route.query.lang) url.query['lang'] = this.$route.query.lang
      this.$router.push(url);
    },
    useHandle(){
      if(!this.isLogin) return this.goLogin()
      if(this.isApp == "ios"){
        window.webkit.messageHandlers.backToHomePage.postMessage({});
      }else if(this.isApp == "android"){
        window.hotelapp.startLoginPage()
      }else if(this.isApp == "wx"){
        WxSDK.miniProgram.redirectTo({
          url: '/pages/index/index'
        })
      }else{
        this.$router.push({ name: 'Home'});
      }
    },
    share(node){
      if(!this.isLogin) return this.goLogin()
      if(this.isApp == "android"){
        message.warning(this.$t('invite.tips'))
      }else if(this.isApp == "ios"){
        window.webkit.messageHandlers.receiveWebClick.postMessage({"ShareCodeContent":this.info.spreadDesc});
      }else{
        this.copy(node,this.info.spreadDesc)
      }
    },
    copy(node,text) { 
      let clipboard = new Clipboard(node,{
      // 点击copy按钮，直接通过text直接返回复印的内容
            text:()=> text
        })  
        clipboard.on('success', e => { 
          // 释放内存  
          node.includes('share') ? this.shareShow = true : message.success(this.$t('invite.copy_success'))
          clipboard.destroy()  
        })  
        clipboard.on('error', e => {  
          // 不支持复制  
          message.warning(this.$t('invite.copy_fail'))  
          // 释放内存  
          clipboard.destroy()  
        })  
    },
  }
}
</script>
<style lang="scss" scoped>
.banner-img{
  width: 100%;
  height: auto;
}
.share-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  .share-inner{
    width: 320px;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 16px;
    .share-cont{
      padding:0 20px 20px 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .title{
        color: #222222;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
      }
      .text{
        text-align: center;
        color: #222222;
        font-size: 12px;
        margin: 10px 0 15px 0;
      }
    }
  }
}
#invite{
  padding-bottom: 40px;

  .divider{
    font-size: 32px;
    color: #222222;
    border-color:#B5B5B5;
    margin: 120px 0 80px 0;
  }
  .records{
    width: 55%;
    height: 120px;
    margin: 0 auto;
    box-shadow: 0px 2px 10px 0px rgba(23,59,92,0.08);
    background: #FFFFFF;
    border-radius: 15px;
    padding: 15px;
    .item{
      flex: 1;
      padding:0 10px;
      &:first-child{
        border-right: 1px solid #B5B5B5;
      }
    }
    .number{
      color: #3A9EFB;
      font-size: 24px;
      font-weight: bold;
      
    }
    .text{
      margin-top: 5px;
      color: #5D5D5D;
      font-size: 18px;
    }
    .use{
      min-width: 66px;
      margin-left: 20px;
    }
  }
  .empty{
    color: #333333;
    font-size: 18px;
    text-align: center;
  }
  .swiper-pc{
    display: block;
  }
  .swiper-xs{
    display: none;
  }
  .invite-code-pc{
    height: 180px;
    background-image: url("../assets/invite_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 28px;
    color: #222222;
    .code{
      color:#3A9EFB;
      font-size: 50px;
      font-weight: 900;
      margin: 0 40px;
    }
    .code-copy{
      width: 140px;
    }
  }
  .invite-container-pc{
    .title{
      margin: 80px 0 20px 0;
      color:#222222;
      font-size: 32px;
      text-align: center;
    }
    .rule{
      font-size: 18px;
      color: #3A9EFB;
      text-align: center;
      text-decoration:underline;
      cursor: pointer;
    }
    .step{
      display: flex;
      justify-content: center;
      flex-flow: wrap;
      .step-item{
        margin: 40px;
        width: 269px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #CCCCCC;
        padding: 40px 40px 40px 30px;
        color: #333333;
        font-size: 14px;
        .number{
          font-size: 50px;
          font-weight: 900;
        }
        .title{
          margin: 10px 0;
          font-size: 18px;
          font-weight: bold;
          text-align: start;
        }
        .text{
          word-break: break-word;
        }
      }
    }
    .share-pc{
      width: 340px;
    }
  }
  .invite-container-xs{
    display: none;
  }
}
@media(max-width:768px){
  #invite{
    background-color: #F3F3F3;
    padding-bottom: 20px;
    .container{
      padding: 0 20px;
    }
    .divider{
      font-size: 14px;
      color: #5D5D5D;
      border-color:#B5B5B5;
      margin: 15px 0;
    }
    .records{
      width: 100%;
      height: auto;
      margin: 0 auto;
      box-shadow: none;
      background: #FFFFFF;
      border-radius: 15px;
      padding: 15px;
      .item{
        flex: 1;
        &:first-child{
          border-right: 1px solid #B5B5B5;
        }
      }
      .number{
        color: #3A9EFB;
        font-size: 24px;
        font-weight: bold;
        
      }
      .text{
        margin-top: 2px;
        color: #5D5D5D;
        font-size: 14px;
      }
      .use{
        min-width: 66px;
        margin-left: 20px;
      }
    }
    .empty{
      color: #5D5D5D;
      font-size: 14px;
      text-align: center;
    }
    .swiper-pc{
    display: none;
    }
    .swiper-xs{
      display: block;
    }
    .invite-code-pc{
      display: none;
    }
    .invite-container-pc{
      display: none;
    }
    .invite-container-xs{
      margin-top: -30px;
      display: block;
      .invite-explain{
        padding: 0 20px 25px 20px;
        background-color: #fff;
        border-radius:0  0 15px 15px;
        .title{
          font-size: 16px;
          color: #222222;
          .rule{
            margin-left: 10px;
            font-size: 12px;
            color:#3A9EFB;
          }
        }
        .steps{
          margin: 15px 0 20px 0;
          
          .step-item{
            position: relative;
            padding-bottom: 15px;
            &::before{
              position: absolute;
              content: "";
              width: 2px;
              height: 100%;
              left: 10px;
              top: 16px;
              border-left: 1px dashed  #3A9EFB;
            }
            &:last-child{
              padding-bottom: 0;
              &::before{
                display: none;
              }
            }
            .title{
              color: #222222;
              font-size: 14px;
              margin-left: 10px;
              font-weight: bold;
            }
            .text{
              color:#5D5D5D;
              margin-left: 23px;
              word-break: break-word;
            }
          }
        }
      }
      .invite-code{
        position: relative;
        height: 140px;
        padding: 15px 20px;
        border-radius: 15px 15px 0 0;
        background: radial-gradient(circle at 15px 120px, transparent 0%, transparent 15px, #fff 15px) -15px;
        &::after {
          content: '';
          width: 88%;
          height: 4px;
          border-bottom-width: 3px;
          border-bottom-style: dashed;
          transform: translateX(-50%);
          border-color: #F3F3F3;
          position: absolute;
          left: 50%;
          bottom: 18px;
        }
        .title{
          font-size: 14px;
          text-align: center;
          color: #5D5D5D;
          margin-bottom: 10px;
        }
        .code{
          color:#3A9EFB;
          font-size: 28px;
          font-weight: bold;
          height: 60px;
          background: rgba(58, 158, 251, 0.10);
          border-radius: 15px;
          .copy{
            color: #5D5D5D;
            margin-left: 20px;
            font-size: 12px;
            font-weight: normal;
          }
        }
      }
    }
  }
}
</style>